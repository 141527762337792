<template>
  <div>
    <div class="form-page" v-if="false">
        <div class="prohibit">
            <img src="@/assets/img/prohibit.png" alt="">
            <p>{{ title }}</p>
        </div>
    </div>

    <div class="form-page" v-else>

      <choiceLang />
      
      <div class="form-title">
        <span class="text">
          <span v-if="lang === 'zh'">{{ smokingZoneCnrDesign.TitleChinese }}</span>
          <span v-if="lang === 'en'">{{ smokingZoneCnrDesign.TitleEnglish }}</span>
        </span>
        <!-- <img src="@/assets/img/smoking_guy.png" alt="" /> -->

        
      </div>
      <!-- <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
              <el-step v-for="i in 6" :key="i" :title="'步驟'+i" ></el-step>
          </el-steps>


          <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->

      <div class="form-wrap">
        <!-- <div class="title">
          <span>白 41</span>
          <select v-model="seat.color">
            <option value="" disabled selected hidden>請輸入區域 (Area)</option>
            <option v-for="color in colors" :key="color.id" :value="color.id">{{ color.name }}</option>
          </select>
          <select v-model="seat.name" >
            <template v-if="seat.color === 'yellow'">
              <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
              <option v-for="item in yellowSeat" :key="item" :value="item">{{ item }}</option>
            </template>

            <template v-if="seat.color === 'green'">
              <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
              <option v-for="item in greenSeat" :key="item" :value="item">{{ item }}</option>
            </template>

            <template v-if="seat.color === 'blue'">
              <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
              <option v-for="item in blueSeat" :key="item" :value="item">{{ item }}</option>
            </template>

            <template v-if="seat.color === 'red'">
              <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
              <option v-for="item in redSeat" :key="item" :value="item">{{ item }}</option>
            </template>

            <template v-if="seat.color === 'white'">
              <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
              <option v-for="item in whiteSeat" :key="item" :value="item">{{ item }}</option>
            </template>


          </select>
        </div> -->
          <!-- <div class="desc">
            同仁您好：<br>
            提醒您，抽菸前請依卡片顏色、號碼站至對應區域內之格子中，並填寫以下問卷後，始可抽菸，如發現未填寫或填寫不實資訊者，將依廠規懲處，謝謝您的配合。<br>
            Please note that you can start smoking only when you stand in the right square in accordance with the number and color of the card you take. If it is found that you do not submit the information or submit the information dishonestly, a punishment will be given to you according to the company's rule. Thank you for your cooperation.
          </div> -->
          <ValidationObserver v-slot="{ valid, changed, validated, reset }" ref="form">
            <form
              :key="index"
              @submit.prevent.once="addSmokingZoneCnr"
              @reset.prevent="reset"
            >
              <div class="title">
                <span class="title">
                  <!-- 白 41 -->
                  <span v-if="lang === 'zh'">{{ seat.SmokeAreaChinese }} {{ seat.SeatNo }}</span>
                  <span v-if="lang === 'en'">{{ seat.SmokeAreaEnglish }} {{ seat.SeatNo }}</span>
                </span>
                <!-- <ValidationProvider
                  name="區域"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select v-model="seat.color">
                    <option value="" disabled selected hidden>請輸入區域 (Area)</option>
                    <option v-for="color in colors" :key="color.id" :value="color.id">{{ color.name }} ({{ color.id }})</option>
                  </select>
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>  

                <ValidationProvider
                  name="區域"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select v-model="seat.name" >
                    <template v-if="seat.color === 'yellow'">
                      <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
                      <option v-for="item in yellowSeat" :key="item" :value="item">{{ item }}</option>
                    </template>

                    <template v-if="seat.color === 'green'">
                      <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
                      <option v-for="item in greenSeat" :key="item" :value="item">{{ item }}</option>
                    </template>

                    <template v-if="seat.color === 'blue'">
                      <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
                      <option v-for="item in blueSeat" :key="item" :value="item">{{ item }}</option>
                    </template>

                    <template v-if="seat.color === 'red'">
                      <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
                      <option v-for="item in redSeat" :key="item" :value="item">{{ item }}</option>
                    </template>

                    <template v-if="seat.color === 'white'">
                      <option value="" disabled selected hidden>請輸入座位號碼 (Seat Number)</option>
                      <option v-for="item in whiteSeat" :key="item" :value="item">{{ item }}</option>
                    </template>


                  </select>
                  <span v-if="errors[0]" class="text-danger">
                        {{ errors[0] }}
                  </span>
                </ValidationProvider>   -->
              </div>
              <div class="desc">
                <!-- 同仁您好：<br>
                提醒您，抽菸前請依卡片顏色、號碼站至對應區域內之格子中，並填寫以下問卷後，始可抽菸，如發現未填寫或填寫不實資訊者，將依廠規懲處，謝謝您的配合。<br>
                Please note that you can start smoking only when you stand in the right square in accordance with the number and color of the card you take. If it is found that you do not submit the information or submit the information dishonestly, a punishment will be given to you according to the company's rule. Thank you for your cooperation. -->
                <span v-if="lang === 'zh'">{{ smokingZoneCnrDesign.DescriptionChinese }}</span>
                <span v-if="lang === 'en'">{{ smokingZoneCnrDesign.DescriptionEnglish }}</span>
              </div>

              <div class="field">
                <ValidationProvider
                  name="工號"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" :placeholder="i18n('PlaceHolder.BadgeNumber')" v-model="staffInfo.BadgeNo" @blur="getStaffName" />
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="field">
                <ValidationProvider
                  name="姓名"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" :placeholder="i18n('PlaceHolder.Name')" v-model="staffInfo.Name" readonly />
                  <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>


              <div class="button-wrap">        
                <button type="submit" class="btn btn-warning">{{ i18n("Buttons.Submit") }}</button>
                <span style="cursor: default;" v-if="(changed && !valid && validated) || (!changed && !valid && validated)" class="btn text-danger">尚有必填欄位未填到 There are still required fields not filled in</span>
              </div>
            </form>
          </ValidationObserver>
          <!-- <div class="field">
            <input type="text" placeholder="請輸入廠處" />
          </div>
          <div class="field">
            <input type="text" placeholder="請輸入部門" />
          </div> -->
      </div>
    </div>

  </div>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { apiGetStaffName } from '@/apis/common'
import { apiAddSmokingZoneCnr } from '@/apis/questionnaire'
import choiceLang from '@/components/common/choiceLang.vue'

export default {
  components: {
    choiceLang,
  },
  data() {
    return {
      staffInfo: {
        BadgeNo: null,  //  工號
        Name: null,
      },
      questionnaire: {
        // AreaNo: '白 41',
        AreaNo: '',
      },
      colors: [
        {
          id: 'yellow',
          name: '黃'
        },
        {
          id: 'green',
          name: '綠'
        },
        {
          id: 'blue',
          name: '藍'
        },
        {
          id: 'red',
          name: '紅'
        },
        {
          id: 'white',
          name: '白'
        }, 
      ],
      yellowSeat: ['10', '11', '12', '13', '20'],
      greenSeat: ['6', '7', '8', '9', '10', '11', '12', '17', '18', '19', '20'],
      blueSeat: ['1', '2', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'],
      redSeat: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      whiteSeat: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23','24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50'],
      seat: {
        color: '',
        name: ''
      },
      placeId: '',
      choiceColor: {},  //  選擇的顏色，完成需顯示的內容

      title: '',
      index: 0, //  避免被once後，無法再次送出
    };
  },
  watch: {
    'seat.color': function() {
      let filterColor = this.colors.filter(color => {
        return color.id === this.seat.color
      })

      this.choiceColor = filterColor[0]
    }
  },
  computed: {
    ...mapGetters(['smokingZoneCnrDesign', 'lang'])
  },
  methods: {
    ...mapActions(['getSmokingZoneCnrDesign']),
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n (keyStr) {
      return this.$t(keyStr);
    },
    getStaffName() {
      apiGetStaffName({
        badgeNo: this.staffInfo.BadgeNo
      }).then((response) => {

        if(response.status === 200) {
          this.staffInfo.Name = response.data
        }
      })
      .catch(() => {
        this.staffInfo.BadgeNo = null
        this.$refs.form.reset();
      })
    },
    async addSmokingZoneCnr() {

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return
      }

      apiAddSmokingZoneCnr({
        ...this.staffInfo,
        // ...this.questionnaire
        // AreaNo: `${this.seat.SmokeAreaChinese}${this.seat.SeatNo}`
        AreaNo: this.questionnaire.AreaNo
      }).then((response) => {

        if(response.status === 200) {
          this.$notify({
              title: '成功',
              message: '已成功送出',
              type: 'success',
              duration: 3500
          });

          // this.$router.push('finish')
          this.$router.push({ name: 'Finish',  params: { staff: JSON.stringify(this.staffInfo), seat: JSON.stringify(this.seat) }})
        }
      })
      .catch(() => {
        this.index++ ;
      })
 
    },
    //  取得吸菸區位子資訊
    getSeatNoFromGuid() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/GetSeatNoFromGuid/${vm.placeId}`;

      // this.updateLoading(true)
      
      vm.$http.get(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
              this.seat = response.data
            }

            // this.updateLoading(false)
      })
      .catch(() => {
        // this.updateLoading(false)
      })
    }
  },
  created() {
    // console.log(this.$route.params.id)

    this.placeId = this.$route.params.id

    if(this.placeId !== '') {
      this.getSeatNoFromGuid();
      this.questionnaire.AreaNo = this.$route.params.id
    }

    this.getSmokingZoneCnrDesign();

    this.title = window.Disabled.title
  }
};
</script>

<style lang="scss" scoped>
.form-page {
  background-color: #bfe3bf;
  text-align: center;
  min-height: 100vh;
  padding: 90px 20px;
  // background-image: url("~@/assets/img/bg.png");
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    // padding: 30px;
    padding: 60px 30px;
  }

  .form-title {
    width: 400px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 280px;
      margin: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text {
      
      background-image: url("~@/assets/img/title_bg.png");
      padding: 10px 20px;
      border-radius: 44px;
      color: #000;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }
    .page-number {
      span {
        font-size: 2em;
      }
    }
  }
  .form-wrap {
    width: 550px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      text-align: center;
      margin-bottom: 15px;

      span {
        display: inline-block;
        background: linear-gradient(rgba(#fff, 0) 60%, #fff 0%) !important;
        font-weight: bold;
        padding: 0 15px;
      }
    }

    .desc {
      font-size: 0.9em;
      line-height: 200%;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .field {
      input {
        border: none;
        padding: 0 10px;
        line-height: 40px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
        &:focus {
          outline: none;
        }
      }
    }

    .checkbox {
      display: block;
      cursor: pointer;

      .text {
        &:after {
          content: "\f058";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          color: #b6d3b6;
          position: absolute;
          left: 10px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          color: #d7e995;
          font-size: 1.2em;
        }

        position: relative;
        background-color: #97bd97;
        line-height: 40px;
        display: block;
        padding: 0 10px;
        padding-left: 40px;
        border-radius: 35px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        color: #fff;
      }

      input {
        display: none;
        &:checked {
          & + .text {
            background-color: #039577;
            color: #fff;
            &:after {
              color: #fff;
            }
          }
        }
      }
    }

    textarea {
      border: none;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .button-wrap {
      margin-top: 30px;
    }
  }
}

select {
  border: none;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px argb(0, 0, 0, 20%);
  margin: .4rem;

  color: gray;

  option:not(:first-of-type) {
    color: black;
  }
}

.prohibit {
    width: 500px;
    display: inline-block;
    // vertical-align: middle;
    padding: 0 30px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    img {
        width: 100%;
        // max-width: 280px;
        margin-bottom: 30px;
    }

    p {
        width: 100%;
        font-size: 1.25rem;
        color: #5a5c69!important;
        font-weight: 900;
    }
}

</style>
